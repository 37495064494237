import service from "@/utils/request";


//分页查询所有
export const getMerchantInfoData = (params) => service({
    url: '/mchInfo/queryPage',
    method: "get",
    params: params
});

//添加
export const addMerchantInfo = (data) => service({
    url: '/mchInfo/add',
    method: "post",
    data: data,
});

//更新
export const updateMerchantInfo = (data) => service({
    url: '/mchInfo/edit',
    method: "post",
    data: data,
});

//删除（一个/多个）
export const deletesMerchantInfo = (data) => service({
    url: '/mchInfo/delete/ids',
    method: "post",
    data: data,
});

//修改商户状态
export const updateMerchantStatus = (mchNo,status) => service({
    url: `/mchInfo/change/${mchNo}/${status}`,
    method: "post",
});
<template>
  <div>
    <!-- 页头搜索 -->
    <div class="input">
      <div class="input-font">
        <div class="fontdiv">商户号</div>
        <el-input placeholder="请输入商户号" v-model="searchForm.mchNo" clearable size="medium"></el-input>
      </div>
      <div class="input-font">
        <div class="fontdiv">商户名称</div>
        <el-input placeholder="请输入商户名称" v-model="searchForm.mchName" clearable size="medium"></el-input>
      </div>
      <div class="input-font">
        <div class="fontdiv">商户简称</div>
        <el-input placeholder="请输入商户简称" v-model="searchForm.mchShortName" clearable size="medium"></el-input>
      </div>
      <div class="input-font">
        <div class="fontdiv">联系人姓名</div>
        <el-input placeholder="请输入联系人姓名" v-model="searchForm.contactName" clearable size="medium"></el-input>
      </div>
      <div class="input-font">
        <div class="fontdiv">联系人手机号</div>
        <el-input placeholder="请输入联系人手机号" v-model="searchForm.contactTel" clearable size="medium"></el-input>
      </div>
      <div class="input-font">
        <div class="fontdiv">联系人邮箱</div>
        <el-input placeholder="请输入联系人邮箱" v-model="searchForm.contactEmail" clearable size="medium"></el-input>
      </div>
      <div class="input-font">
        <div class="fontdiv">类型</div>
        <el-select v-model="searchForm.type" placeholder="请选择类型" clearable class="select-width" size="medium">
          <el-option label="普通商户" :value="1" />
          <el-option label="未知商户" :value="5" />
        </el-select>
      </div>

      <el-button class="button-height" type="primary" icon="el-icon-search" :loading="false" @click="getTableData()">搜索
      </el-button>
      <el-button class="button-height" plain icon="el-icon-refresh" @click="resetSearch()">重置</el-button>
    </div>

    <!-- 页头按钮 -->
    <el-row>
      <el-button size="medium" type="success" plain icon="el-icon-download" :disabled="false" @click="add()">添加
      </el-button>
      <el-button size="medium" type="danger" plain icon="el-icon-download" :disabled="false" @click="deleteList()">批量删除
      </el-button>
    </el-row>

    <!-- 列表渲染 -->
    <div class="table">
      <el-table :data="tableData" ref="multipleTable" tooltip-effect="dark" @selection-change="handleSelectionChange">
        <el-table-column type="selection" min-width="60"></el-table-column>
        <el-table-column prop="mchNo" label="商户号" min-width="170"></el-table-column>
        <el-table-column prop="mchName" label="商户名称" min-width="200"></el-table-column>
        <el-table-column prop="mchShortName" label="商户简称" min-width="150"></el-table-column>
        <el-table-column prop="type" label="类型" min-width="100">
          <template #default="scope">
            <el-tag v-if="scope.row.type === 1">普通商户</el-tag>
            <el-tag v-else>未知类型</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="contactName" label="联系人姓名" min-width="110"></el-table-column>
        <el-table-column prop="contactTel" label="联系人手机号" min-width="120"></el-table-column>
        <el-table-column prop="contactEmail" label="联系人邮箱" min-width="170"></el-table-column>
        <el-table-column prop="status" label="商户状态" min-width="90">
          <template #default="scope">
            <div @click="changeStatus(scope.row)">
              <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0"></el-switch>
            </div>
          </template>
        </el-table-column>
        <!-- 浮动侧边栏 -->
        <el-table-column label="操作" min-width="130" fixed="right" align="center">
          <template #default="scope">
            <el-link class="marginRight" @click="editRow(scope.row)" type="primary" :underline="false">编辑</el-link>
            <el-link @click="deleteRow(scope.row.mchNo)" type="danger" :underline="false">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页器 -->
    <Pagination :total="total" :page="pageNumber" :size="pageSize" @getPage="getPage($event)"
      @getSize="getSize($event)">
    </Pagination>

    <!-- 修改  -->
    <el-drawer title="修改" :visible.sync="updateFormVisible" direction="rtl" :before-close="handleClose" size="35%">
      <div class="drawer-content">
        <el-form :model="updateForm" :rules="rules" ref="updateForm" label-width="110px" class="demo-ruleForm">
          <el-form-item label="商户名称" prop="mchName">
            <el-input v-model="updateForm.mchName" placeholder="请输入商户名称"></el-input>
          </el-form-item>
          <el-form-item label="商户简称" prop="mchShortName">
            <el-input v-model="updateForm.mchShortName" placeholder="请输入商户简称"></el-input>
          </el-form-item>
          <el-form-item label="类型" prop="type">
            <el-select v-model="updateForm.type" placeholder="请选择类型">
              <el-option label="普通商户" :value="1"></el-option>
              <el-option label="未知商户" :value="5" />
            </el-select>
          </el-form-item>
          <el-form-item label="联系人姓名" prop="contactName">
            <el-input v-model="updateForm.contactName" placeholder="请输入联系人姓名"></el-input>
          </el-form-item>
          <el-form-item label="联系人手机号" prop="contactTel">
            <el-input v-model="updateForm.contactTel" placeholder="请输入联系人手机号"></el-input>
          </el-form-item>
          <el-form-item label="联系人邮箱" prop="contactEmail">
            <el-input v-model="updateForm.contactEmail" placeholder="请输入联系人邮箱"></el-input>
          </el-form-item>
          <el-form-item label="商户备注" prop="remark">
            <el-input v-model="updateForm.remark" placeholder="请输入商户备注"></el-input>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <a-button class="marginRight" @click="updateFormCancel">
            取消
          </a-button>
          <a-button class="marginRight" @click="resetForm">
            重置
          </a-button>
          <a-button type="primary" @click="editSubmit">
            保存
          </a-button>
        </div>
      </div>
    </el-drawer>

    <!-- 添加 -->
    <el-drawer title="添加" :visible.sync="addFormVisible" direction="rtl" :before-close="handleClose" size="35%">
      <div class="drawer-content">
        <el-form :model="addForm" :rules="rules" ref="addForm" label-width="110px" class="demo-ruleForm">
          <el-form-item label="商户名称" prop="mchName">
            <el-input v-model="addForm.mchName" placeholder="请输入商户名称"></el-input>
          </el-form-item>
          <el-form-item label="商户简称" prop="mchShortName">
            <el-input v-model="addForm.mchShortName" placeholder="请输入商户简称"></el-input>
          </el-form-item>
          <el-form-item label="类型" prop="type">
            <el-select v-model="addForm.type" placeholder="请选择类型">
              <el-option label="普通商户" :value="1"></el-option>
              <el-option label="未知商户" :value="5" />
            </el-select>
          </el-form-item>
          <el-form-item label="联系人姓名" prop="contactName">
            <el-input v-model="addForm.contactName" placeholder="请输入联系人姓名"></el-input>
          </el-form-item>
          <el-form-item label="联系人手机号" prop="contactTel">
            <el-input v-model="addForm.contactTel" placeholder="请输入联系人手机号"></el-input>
          </el-form-item>
          <el-form-item label="联系人邮箱" prop="contactEmail">
            <el-input v-model="addForm.contactEmail" placeholder="请输入联系人邮箱"></el-input>
          </el-form-item>
          <el-form-item label="商户备注" prop="remark">
            <el-input v-model="addForm.remark" placeholder="请输入商户备注"></el-input>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <a-button class="marginRight" @click="addFormCancel">
            取消
          </a-button>
          <a-button class="marginRight" @click="resetForm">
            重置
          </a-button>
          <a-button type="primary" @click="addSubmit">
            确认
          </a-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  getMerchantInfoData,
  deletesMerchantInfo,
  updateMerchantInfo,
  updateMerchantStatus,
  addMerchantInfo,
} from "@/api/merchantManagement/merchantInfo";
import Pagination from "@/components/Pagination";

export default {
  name: "merchantInfo",
  components: { Pagination },
  data() {
    return {
      //页头搜索数据
      searchForm: {},
      //保存多选框选中的行数据
      selectedRows: [],
      //渲染列表数据
      tableData: [],
      //总条数
      total: 0,
      //当前页数
      pageNumber: 1,
      //当前每页条数
      pageSize: 5,
      //修改 是否展示
      updateFormVisible: false,
      //修改表单数据
      updateForm: {},
      //添加 是否展示
      addFormVisible: false,
      //添加表单数据
      addForm: {},
      //添加表单数据规则
      rules: {
        mchName: [
          { required: true, message: '请输入商户名称', trigger: 'blur' }
        ],
        mchShortName: [
          { required: true, message: '请输入商户简称', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
        contactName: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' }
        ],
        contactTel: [
          { required: true, message: '请输入联系人手机号', trigger: 'blur' }
        ],
        contactEmail: [
          { required: true, message: '请输入联系人邮箱', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '请输入商户备注', trigger: 'blur' }
        ],
      },
    };
  },

  mounted() {
    this.getTableData();
  },

  methods: {
    //分页查询
    async getTableData() {
      const data = { ...this.searchForm, pageNum: this.pageNumber, pageSize: this.pageSize };
      const res = await getMerchantInfoData(data);
      this.tableData = res.data.rows
      this.total = parseInt(res.data.total)
    },

    // 页头重置
    resetSearch() {
      this.searchForm = {}
      this.getTableData()
    },

    //通过选中的多行数据存入数组
    handleSelectionChange(selection) {
      this.selectedRows = selection;
      console.log(this.selectedRows)
    },

    //表单重置（添加/修改）
    resetForm() {
      this.addForm = {}
      this.updateForm = {}
    },

    //批量删除按钮
    async deleteList() {
      this.$confirm('此操作将永久删除选中商户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = this.selectedRows.map((item) => {
          return item.mchNo
        })
        console.log(data)
        const res = await deletesMerchantInfo(data);
        console.log(res)
        this.$message.success("删除成功");
        this.getTableData();
      }).catch((e) => {
        if (e == 'cancel') {
          this.$message.info("已取消删除");
        }
      });
    },

    //添加按钮
    add() {
      this.addFormVisible = true
    },

    //添加抽屉取消按钮
    addFormCancel() {
      this.addFormVisible = false
      this.addForm = {}
    },

    //添加抽屉提交按钮
    async addSubmit() {
      this.addForm.isvNo = '1'
      const res = await addMerchantInfo(this.addForm)
      this.$message.success("添加成功");
      this.getTableData()
      this.addFormVisible = false
    },

    // 修改状态
    async changeStatus(row) {
      console.log(row.status)
      var text = ""
      if (row.status === 0) {
        text = "禁用成功"
      } else if (row.status == 1) {
        text = "启用成功"
      }
      const res = await updateMerchantStatus(row.mchNo, row.status)
      this.$message.success(text);
    },

    //修改
    editRow(row) {
      this.updateForm = row
      this.updateFormVisible = true
    },

    //修改抽屉取消按钮
    updateFormCancel() {
      this.updateFormVisible = false
      this.updateForm = {}
    },

    //修改抽屉保存按钮
    async editSubmit() {
      const res = await updateMerchantInfo(this.updateForm)
      this.$message.success("修改成功");
      this.updateFormVisible = false
    },

    // 删除单条
    deleteRow(id) {
      this.$confirm('此操作将永久删除该商户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = [id]
        const res = await deletesMerchantInfo(data);
        this.getTableData();
        this.$message.success("删除成功");
      }).catch((e) => {
        if (e == 'cancel') {
          this.$message.info("已取消删除");
        }
      });
    },

    //抽屉关闭回调
    handleClose(done) {
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     done();
      //   })
      //   .catch(_ => { });
      done()
      this.addForm = {}
      this.updateForm = {}
      this.argumentForm = {}
    },

    //获取当前页数
    getPage(value) {
      this.pageNumber = value;
      this.getTableData();
    },

    //获取每页多少条
    getSize(value) {
      this.pageSize = value;
      this.getTableData();
    },
  }
}
</script>

<style scoped lang="less">
/* 搜索框样式 */
.input {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;

  .input-font {
    width: 400px;
    display: flex;
    margin-bottom: 10px;

    .fontdiv {
      color: #999;
      font-weight: 600;
      font-size: 15px;
      width: 150px;
      line-height: 36px;
      text-align: center;
    }

    .select-width {
      width: 400px;
    }
  }

  .button-height {
    height: 36px;
    margin-bottom: 10px;
    margin-left: 15px;
  }

}

/* 表格 */
.table {
  margin-top: 15px;

  .marginRight {
    margin-right: 20px;
  }

  :deep .el-table .el-table__header-wrapper .el-table__header .el-table__cell {
    font-size: 14px;
    color: #999;
    font-weight: 600px;
    text-align: center;
    background-color: #f2f2f2;
  }

  :deep .el-table th.el-table__cell.is-leaf {
    background-color: #f2f2f2 !important;
  }

  :deep .el-table .el-table__body-wrapper .el-table__body .el-table__row .el-table__cell .cell {
    text-overflow: clip;
    text-align: center;
  }
}

/* 抽屉内容 */
.drawer-content {
  padding: 0 30px 70px 20px;

  .drawer-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;
    text-align: right;
    z-index: 1;
  }

  .marginRight {
    margin-right: 8px;
  }
}
</style>